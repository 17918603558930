import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "../SectionContainer";
import ResponsiveColumns from "@components/ResponsiveColumns";
import TopLeftContent from "./landingTop/TopLeftContent";
import TopRightContent from "./landingTop/TopRightContent";
import { Button } from "react-bootstrap";

const LandingTop = () => {
  return (
    <>
      <SectionContainer color="gradation" size="extraLarge">
        <LoginLead>
          <a href="https://business.wanpass.me/">
            <Button variant="warning" size="sm" className="_button">
              ログイン
            </Button>
          </a>
        </LoginLead>
        <ResponsiveWrapper>
          <ResponsiveColumns>
            {[
              <LeftWrapper key="left">
                <TopLeftContent />
              </LeftWrapper>,
              <RightWrapper key="right">
                <TopRightContent />
              </RightWrapper>,
            ]}
          </ResponsiveColumns>
        </ResponsiveWrapper>
      </SectionContainer>
    </>
  );
};

export default LandingTop;

const ResponsiveWrapper = styled.div`
  @media ${DEVICE.MOBILE} {
    margin-top: -16px;
  }
`;

const LeftWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
  @media ${DEVICE.MOBILE} {
    padding: 15px 0px;
  }
`;

const RightWrapper = styled.div`
  padding: 48px 0 0 0;
  @media ${DEVICE.MOBILE} {
    padding: 20px 0px;
  }
`;

const LoginLead = styled.div`
  margin-top: -24px;
  margin-bottom: 8px;
  text-align: right;
  @media ${DEVICE.MOBILE} {
    ._button {
      transform: scale(0.8, 0.8);
    }
  }
`;
