import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "@components/SectionContainer";
import TextAndImageCard from "@components/TextAndImageCard";

// 画像と文字列を同じ幅にするために、画像の幅をMOBILE_WIDTHとして設定しています。
const MOBILE_WIDTH = 375;

const SupportFeedback = () => {
  return (
    <SectionContainer size="large" color="lightOcher">
      <Wrapper>
        <TitleContainer>
          <h2 className="lp-title">
            導入までしっかりサポート。
            <br />
            導入企業様のご感想をご覧下さい。
          </h2>
        </TitleContainer>
        <TextAndImageCard
          image={
            <StaticImage
              src="../../images/support_feedback/karuizawa_sightseeing_association_president.jpg"
              alt="今回コラボさせていただく「Wan!Pass」は、単にお出かけに便利なアプリというだけではなく、 しつけやマナー啓発のこともしっかり考えられたものになっており、私たちが目指す社会に向けても大きな原動力になると確信しています。"
            />
          }
          text={
            <>
              <ContentTitleWrapper>
                <h1 className="lp-title">軽井沢観光協会 様</h1>
              </ContentTitleWrapper>
              <ContentTextWrapper>
                一般社団法人
                軽井沢観光協会・ドッグツーリズム推進プロジェクトでは「人と犬が健康で
                楽しく幸せに暮らせる町・軽井沢」をスローガンに、軽井沢らしいドッグフレンドリーな
                町づくりを目指しています。今回コラボさせていただく「Wan!Pass」は、単にお出かけに便利なアプリというだけではなく、
                しつけやマナー啓発のこともしっかり考えられたものになっており、私たちが目指す社会に向けても大きな原動力になると確信しています。
                <br />
                <br />
                一般社団法人 軽井沢観光協会
                <br />
                会長　土屋 芳春
              </ContentTextWrapper>
            </>
          }
          mobileWidth={MOBILE_WIDTH}
        />
      </Wrapper>
    </SectionContainer>
  );
};

export default SupportFeedback;

const Wrapper = styled.div`
  @media ${DEVICE.MOBILE} {
    display: block;
    margin: 0 auto;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 40px;
  text-align: center;
  @media ${DEVICE.MOBILE} {
    margin-bottom: 30px;
  }
`;

const ContentTitleWrapper = styled.div`
  margin: auto;
  margin-bottom: 24px;
  text-align: center;
  @media ${DEVICE.MOBILE} {
    text-align: center;
    margin: 10px 0px;
  }
`;

const ContentTextWrapper = styled.div`
  line-height: 1.8;
  @media ${DEVICE.MOBILE} {
    margin: 10px 5px;
  }
`;
