import * as React from "react";
import styled from "styled-components";
import DEVICE, { DEVICE_WIDTH } from "@utils/DEVICE";
import { useWindowSize } from "@utils/useWindowSize";
import SectionContainer from "@components/SectionContainer";
import RainbowPanel from "@components/RainbowPanel";

type Props = {
  youtubeVideoLink: string;
};

const YoutubeVideoContainer = ({ youtubeVideoLink }: Props) => {
  const { windowSize } = useWindowSize();
  const embedVideoHeight =
    windowSize.width >= DEVICE_WIDTH.MOBILE ? "467px" : "280px";

  return (
    <SectionContainer color="ocher" size="large">
      <Wrapper>
        <RainbowPanel noPadding>
          <EmbedVideoWrapper>
            <iframe
              width="100%"
              height={embedVideoHeight}
              src={youtubeVideoLink}
              allowFullScreen
            />
          </EmbedVideoWrapper>
        </RainbowPanel>
      </Wrapper>
    </SectionContainer>
  );
};

export default YoutubeVideoContainer;

const Wrapper = styled.div`
  margin: auto;
  @media ${DEVICE.MOBILE} {
    display: block;
    padding: 30px 0px;
  }
`;

const EmbedVideoWrapper = styled.div`
  @media ${DEVICE.MOBILE} {
    margin: 0 auto;
  }
  iframe {
    display: block; // See: https://web-begginer-log.com/mysterious-space/
  }
`;
