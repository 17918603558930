import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";

const RainbowLine = () => <Line />;

export default RainbowLine;

const Line = styled.div`
  height: 3px;
  background: linear-gradient(
    0.2turn,
    #f3b6cd,
    #ffe599,
    #a2d5af,
    #89c7ee,
    #a49bcc
  );
  @media ${DEVICE.MOBILE} {
    margin: 0 auto;
  }
`;
