import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";
import { Body1 } from "./Typo";
import { OnlyPc } from "./shared/ToggleByWidth";

type Props = {
  text: string;
  image: JSX.Element;
};

const IntroduceCompanyDetail = ({ text, image }: Props) => {
  return (
    <DetailContainer>
      <ImageWrapper>{image}</ImageWrapper>
      <OnlyPc>
        <Body1>
          <TextWrapper>{text}</TextWrapper>
        </Body1>
      </OnlyPc>
    </DetailContainer>
  );
};

export default IntroduceCompanyDetail;

const DetailContainer = styled.div`
  @media ${DEVICE.MOBILE} {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  @media ${DEVICE.MOBILE} {
    margin-bottom: 12px;
  }
`;

const TextWrapper = styled.div`
  display: table;
  margin: auto;

  @media ${DEVICE.MOBILE} {
    margin-bottom: 36px;
  }
`;
