import * as React from "react";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import COLORS from "@utils/COLORS";
import DEVICE from "@utils/DEVICE";

type Props = PropsWithChildren & {
  size?: "medium" | "large" | "extraLarge";
  color?: "white" | "lightOcher" | "ocher" | "lightGray" | "gradation";
};

const SectionContainer: FC<Props> = ({
  size = "medium",
  color = "white",
  children,
}) => {
  const bgColor = {
    white: COLORS.white,
    lightOcher: COLORS.lightOcher,
    ocher: COLORS.ocher,
    lightGray: COLORS.lightGray,
    gradation: "gradation",
  }[color];

  const padding = {
    medium: 140,
    large: 100,
    extraLarge: 0,
  }[size];

  return (
    <Wrapper bgColor={bgColor}>
      <Inner padding={padding}>{children}</Inner>
    </Wrapper>
  );
};

export default SectionContainer;

const Wrapper = styled.div<{ bgColor: string }>`
  ${(p) =>
    p.bgColor === "gradation"
      ? `background: linear-gradient(0.25turn, ${COLORS.white}, #f3f7e3, #d4fbfd);`
      : `background-color: ${p.bgColor};`}
  max-width: 100%;
  margin: 0 auto;
`;

const Inner = styled.div<{ padding: number }>`
  margin: 0 auto;
  max-width: 1000px;
  padding: 60px ${(p) => p.padding}px;
  @media ${DEVICE.MOBILE} {
    padding: 30px 10px;
    display: block;
  }
`;
