import DEVICE from "@utils/DEVICE";
import * as React from "react";
import styled from "styled-components";

type Direction = {
  pc?: "default" | "reverse";
  sp?: "default" | "reverse";
};
type ChildMargin = 0 | "auto";

type Props = {
  children: React.ReactNode[];
  gap?: number;
  childMargin?: ChildMargin;
  mobileWidth?: number;
  isResponsiveDisplay?: boolean;
  direction?: Direction;
};

export type ResponsiveColumnsDirection = Direction;

const ResponsiveColumns = ({
  children,
  gap = 0,
  childMargin = 0,
  mobileWidth = undefined,
  isResponsiveDisplay = true,
  direction = {},
}: Props) => {
  const numColumns = children.length;
  return (
    <Wrapper isResponsiveDisplay={isResponsiveDisplay} direction={direction}>
      {children.map((child, index) => (
        <ChildrenWrapper
          numColumns={numColumns}
          gap={gap}
          childMargin={childMargin}
          mobileWidth={mobileWidth}
          key={index}
        >
          {child}
        </ChildrenWrapper>
      ))}
    </Wrapper>
  );
};

export default ResponsiveColumns;

const _flexDirection = (type: Direction["pc"]): string =>
  ({
    default: "row",
    reverse: "row-reverse",
  }[type ?? "default"]);

const Wrapper = styled.div<{
  isResponsiveDisplay: boolean;
  direction: Direction;
}>`
  display: flex;
  flex-direction: ${(p) => _flexDirection(p.direction.pc)};

  @media ${DEVICE.MOBILE} {
    ${(p) =>
      p.isResponsiveDisplay &&
      `
        display: block;
      `}
    flex-direction: ${(p) => _flexDirection(p.direction.sp)};
  }
`;

const ChildrenWrapper = styled.div<{
  numColumns: number;
  gap: number;
  childMargin: ChildMargin;
  mobileWidth: number | undefined;
}>`
  width: ${(p) => 100 / p.numColumns}%;
  padding: 0 ${(p) => p.gap}px;
  margin: ${(p) => p.childMargin};
  align-items: center;
  @media ${DEVICE.MOBILE} {
    width: ${(p) => (p.mobileWidth ? `${p.mobileWidth}px` : "100%")};
    margin: 0 auto;
    padding: 0 ${(p) => p.gap / 2}px;
  }
`;
