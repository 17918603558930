import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";

export type IconWrapperSizeProps = {
  size?: number;
  width?: number;
  height?: number;
};

type Props = PropsWithChildren & IconWrapperSizeProps;

const IconWrapper: FC<Props> = ({ size, width, height, children }) => (
  <Wrapper width={width ?? size} height={height ?? size}>
    {children}
  </Wrapper>
);

export default IconWrapper;

const Wrapper = styled.span<{ width?: number; height?: number }>`
  &,
  .gatsby-image-wrapper,
  .gatsby-image-wrapper img {
    ${
      (p) =>
        (p.width &&
          `
      width: ${p.width}px !important;
    `) ||
        `width: auto;` // Safari で height のみ指定だと表示バグるので必要
    }
    ${(p) =>
      p.height &&
      `
      height: ${p.height}px !important;
    `}
  }
`;
