import COLORS from "@utils/COLORS";
import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";

type Props = PropsWithChildren<{
  title: string | JSX.Element;
  headPadding?: string;
  contentPadding?: string;
}>;
const AttentionCard: FC<Props> = ({
  title,
  children,
  headPadding = "12px 24px",
  contentPadding = "12px 24px",
}) => {
  return (
    <Wrapper>
      <Head padding={headPadding}>{title}</Head>
      <Content padding={contentPadding}>{children}</Content>
    </Wrapper>
  );
};

export default AttentionCard;

const Wrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 8px;
  overflow: hidden;
`;

const Head = styled.div<{ padding: string }>`
  background-color: ${COLORS.gold};
  padding: ${(p) => p.padding};
`;

const Content = styled.div<{ padding: string }>`
  padding: ${(p) => p.padding};
`;
