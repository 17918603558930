import TitleWithRainbowLineBorder from "@components/TitleWithRainbowLineBorder";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "@components/SectionContainer";
import { Body2 } from "@components/Typo";

const RaiseQuestion = () => {
  return (
    <SectionContainer size="medium" color="lightGray">
      <Wrapper>
        <TitleWithRainbowLineBorder text="わんちゃんを飼育しているペットオーナーの約4割が「ペット同伴可施設が少ない・見つからない」と回答しています。" />
        <ImageWrapper>
          <StaticImage
            src="../../images/raise_question/raise_question.png"
            alt="わんちゃんを飼育しているペットオーナーの約4割が「ペット同伴可施設が少ない・見つからない」と回答しています。"
          />
        </ImageWrapper>
        <NoteWrapper>
          <Body2>※ペッツオーライ調べ（n=1,118）</Body2>
        </NoteWrapper>
      </Wrapper>
    </SectionContainer>
  );
};

export default RaiseQuestion;

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${DEVICE.MOBILE} {
    display: block;
    padding: 30px 0;
  }
`;

const ImageWrapper = styled.div`
  padding: 0px 75px;
  margin-top: 40px;
  @media ${DEVICE.MOBILE} {
    margin: 0 auto;
    margin-top: 20px;
    padding: 20px 0px;
  }
`;
const NoteWrapper = styled.div`
  padding-top: 50px;
  text-align: center;
  @media ${DEVICE.MOBILE} {
    padding-top: 20px;
  }
`;
