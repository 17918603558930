import TitleWithRainbowLineBorder from "@components/TitleWithRainbowLineBorder";
import * as React from "react";
import styled from "styled-components";
import COLORS from "@utils/COLORS";
import DEVICE from "@utils/DEVICE";
import ResponsiveColumns from "./ResponsiveColumns";
import { Body1 } from "./Typo";

type Props = {
  title: string;
  details: {
    text: string | JSX.Element;
    image: JSX.Element;
  }[];
};

const TwoTilesHero = ({ title, details }: Props) => {
  return (
    <DetailContainer>
      <TitleWithRainbowLineBorder text={title} />
      <ResponsiveColumnsContainer>
        <ResponsiveColumns gap={5}>
          {details.map((detail, index) => (
            <Content key={index}>
              <TextWrapper>
                <Body1>{detail.text}</Body1>
              </TextWrapper>
              <ImageWrapper>{detail.image}</ImageWrapper>
            </Content>
          ))}
        </ResponsiveColumns>
      </ResponsiveColumnsContainer>
    </DetailContainer>
  );
};

export default TwoTilesHero;

const DetailContainer = styled.div`
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 40px;
  }
  @media ${DEVICE.MOBILE} {
    margin: 0 auto;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 15px;
    }
  }
`;

const ResponsiveColumnsContainer = styled.div`
  margin-top: 40px;
  @media ${DEVICE.MOBILE} {
    display: block;
    margin-top: 20px;
  }
`;

const Content = styled.div`
  padding: 32px 31.5px 0px 31.5px;
  height: 100%;
  background-color: ${COLORS.ocher};
  border-radius: 20px;

  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media ${DEVICE.MOBILE} {
    width: 100%;
    margin: 14px auto;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 10px;
`;

const ImageWrapper = styled.div`
  padding: 30px;
  text-align: center;
`;
