import BlueBelt from "@components/shared/BlueBelt";
import UnderMarker from "@components/shared/UnderMarker";
import { Body1 } from "@components/Typo";
import COLORS from "@utils/COLORS";
import DEVICE from "@utils/DEVICE";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const TopLeftContent = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <StaticImage
          src="../../../images/landing_top/wide_logo.png"
          alt="Wan!Pass（ワンパス）ペット同伴で売上UP！"
        />
      </HeaderWrapper>
      <ContentWrapper as="h1">
        <div>
          <BlueBelt>
            <BlueBeltTitle>
              日本で約<strong>560万</strong>世帯と言われる
            </BlueBeltTitle>
          </BlueBelt>
        </div>
        <MarkerWrapper>
          <MarkerRow>
            <UnderMarker>ペットオーナーを</UnderMarker>
          </MarkerRow>
          <MarkerRow>
            <UnderMarker>無料で集客するなら</UnderMarker>
          </MarkerRow>
          <MarkerRow>ワンパス！</MarkerRow>
        </MarkerWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default TopLeftContent;

const Wrapper = styled.div`
  max-width: 380px;
`;

const HeaderWrapper = styled.div`
  text-align: center;
  padding-left: 12px;
`;

const ContentWrapper = styled.div`
  padding: 0 0 0 12px;
  text-align: left;
  margin: 24px auto 4px auto;
`;

const BlueBeltTitle = styled(Body1)`
  display: inline;
  color: ${COLORS.white};
  font-weight: normal;
  strong {
    font-size: 1.5em;
    font-weight: normal;
  }
`;

const MarkerWrapper = styled.div`
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 1.2;
  @media ${DEVICE.MOBILE} {
    font-size: 32px;
  }
`;

const MarkerRow = styled.div`
  margin: 16px 0;
  &:first-child {
    margin: 14px 0;
  }
`;
