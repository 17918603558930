import TitleWithRainbowLineBorder from "@components/TitleWithRainbowLineBorder";
import * as React from "react";
import styled from "styled-components";
import IntroduceCompanyDetail from "@components/IntroduceCompanyRow";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "@components/SectionContainer";
import ResponsiveColumns from "@components/ResponsiveColumns";
import { StaticImage } from "gatsby-plugin-image";
import { OnlySp } from "@components/shared/ToggleByWidth";

const IntroduceCompany = () => {
  return (
    <SectionContainer size="large" color="lightGray">
      <Wrapper>
        <TitleWithRainbowLineBorder
          text={`Wan!Pass（ワンパス）は、ペットライフのお困りごと解決の\nパイオニア「ペッツオーライ」が開発したプロダクトです。`}
          shouldAlignCenter={true}
        />

        <ResponsiveColumnsContainer>
          <ResponsiveColumns gap={10} isResponsiveDisplay={false}>
            {[
              <IntroduceCompanyDetail
                key="left"
                text="ペットのお困りゴトを24時間いつでもプロの専門家に相談できるサービス、ペッツオーライ"
                image={
                  <StaticImage
                    src="../images/introduce_pets_all_right/pets_all_right.png"
                    alt="ペットのお困りゴトを24時間いつでもプロの専門家に相談できるサービス、ペッツオーライ"
                  />
                }
              />,
              <IntroduceCompanyDetail
                key="middle"
                text="相談件数約12万件超"
                image={
                  <StaticImage
                    src="../images/introduce_pets_all_right/consultations_total_num.png"
                    alt="ペッツオーライは相談件数約12万件超"
                  />
                }
              />,
              <IntroduceCompanyDetail
                key="right"
                text="平均満足度4.7（5段階中）"
                image={
                  <StaticImage
                    src="../images/introduce_pets_all_right/average_customer_satisfaction.png"
                    alt="ペッツオーライは平均満足度4.7（5段階中）"
                  />
                }
              />,
            ]}
          </ResponsiveColumns>
        </ResponsiveColumnsContainer>
        <OnlySp>
          <SpTextWrapper>
            <SpTextRow>
              ペットのお困りゴトを
              <br />
              24時間いつでもプロの専門家に
              <br />
              相談できるサービス、ペッツオーライ
            </SpTextRow>
            <SpTextRow>相談件数約12万件超</SpTextRow>
            <SpTextRow>平均満足度4.7（5段階中）</SpTextRow>
          </SpTextWrapper>
        </OnlySp>
      </Wrapper>
    </SectionContainer>
  );
};

export default IntroduceCompany;

const Wrapper = styled.div`
  margin: 0 auto;
  @media ${DEVICE.MOBILE} {
    margin: 0 auto;
  }
`;

const ResponsiveColumnsContainer = styled.div`
  margin-top: 48px;
  @media ${DEVICE.MOBILE} {
    display: block;
    margin: 24px auto 12px auto;
    text-align: center;
  }
`;

const SpTextWrapper = styled.div`
  text-align: center;
`;

const SpTextRow = styled.div`
  margin-bottom: 24px;
`;
