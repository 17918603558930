const COLORS = {
  ocher: "#FEF5E8",
  lightOcher: "#FFF7EC",
  lightGray: "#F9FBFF",
  white: "#FFFFFF",
  deepblue: "#5276D3",
  charblue: "#457AE5",
  yellowMarker: "#FFDB56",
  gold: "#FFDB56",
  buttonGreen: "#3DCC85",
  buttonGreenShadow: "#2da96b",
  paleOrange: "#FEF5E8", // 日本でいう肌色
  lightGreen: "#9fe9c5",
};

export default COLORS;
