import DEVICE from "@utils/DEVICE";
import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";

type HeadingStyle = {
  size: [number, number]; // [font-size, mobile-font-size]
  noBold?: boolean;
};
type HeadingType = "h1" | "h2" | "h3" | "h4";
const HEADING_STYLE_MAP: { [k in HeadingType]: HeadingStyle } = {
  h1: {
    size: [24, 18],
  },
  h2: {
    size: [20, 16],
  },
  h3: {
    size: [20, 16],
    noBold: true,
  },
  h4: {
    size: [16, 14],
  },
};

type HeadingTagName = HeadingType | "div" | "span";

type HeadingProps = PropsWithChildren & {
  type: HeadingType;
  as?: HeadingTagName;
};
const Heading: FC<HeadingProps> = ({ type, as = "div", children }) => {
  const styleProps = HEADING_STYLE_MAP[type];

  return (
    <HeadingView {...styleProps} as={as} className="font-title">
      {children}
    </HeadingView>
  );
};

const HeadingView = styled.div<HeadingStyle>`
  font-size: ${(p) => p.size[0]}px;
  font-weight: ${(p) => (p.noBold ? "normal" : "bold")};
  @media ${DEVICE.MOBILE} {
    font-size: ${(p) => p.size[1]}px;
  }
`;

type HProps = PropsWithChildren & { as?: HeadingTagName };

// ヘッダーのTypoはデフォルトでは div 要素として表示します。SEO対策でtagを指定する場合は tag として props に指定してください。
export const H1 = (props: HProps) => <Heading type="h1" {...props} />;
export const H2 = (props: HProps) => <Heading type="h2" {...props} />;
export const H3 = (props: HProps) => <Heading type="h3" {...props} />;
export const H4 = (props: HProps) => <Heading type="h4" {...props} />;

export const Body1 = styled.span`
  font-size: 16px;
  @media ${DEVICE.MOBILE} {
    font-size: 14px;
  }
`;

export const Body2 = styled.span`
  font-size: 14px;
  @media ${DEVICE.MOBILE} {
    font-size: 12px;
  }
`;
