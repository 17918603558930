import AttentionCard from "@components/shared/AttentionCard";
import { CheckIcon } from "@components/shared/Icons";
import { Body2 } from "@components/Typo";
import DEVICE from "@utils/DEVICE";
import React from "react";
import styled from "styled-components";

const TopRightContent = () => {
  const title = (
    <CardTitle as="h2">
      Wan!Pass（ワンパス）はペットオーナー集客に特化した無料でご利用いただける集客プラットホームです。
    </CardTitle>
  );
  const items = [
    <>
      日本で<strong>約560万世帯</strong>と言われるペットオーナーの集客に特化。
    </>,
    <>
      ペットオーナー集客の要点である「<strong>ペット同伴可施設の紹介</strong>」
      と「<strong>施設訪問時の手続き簡略化</strong>」を実現。
    </>,
    <>
      端末などの事前準備は<strong>一切不要</strong>。
    </>,
    <>
      ご利用料金も<strong>ずっと0円</strong>でカンタンにご利用いただけます。
    </>,
  ];

  return (
    <AttentionCardWrapper>
      <AttentionCard
        title={title}
        headPadding="12px 24px"
        contentPadding="16px 32px"
      >
        {items.map((item, i) => (
          <AttentionContentRow key={i}>
            <AttentionCheckWrapper>
              <CheckIcon size={20} />
            </AttentionCheckWrapper>
            {item}
          </AttentionContentRow>
        ))}
      </AttentionCard>
    </AttentionCardWrapper>
  );
};

export default TopRightContent;

const CardTitle = styled.div`
  textalign: "center";
  letter-spacing: 0;
  line-height: 1.4;
  font-weight: bold;
  font-size: 22px;
  @media ${DEVICE.MOBILE} {
    font-size: 18px;
  }
  text-align: center;
`;

const AttentionCardWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const AttentionContentRow = styled(Body2)`
  display: block;
  letter-spacing: 0;
  margin: 16px 0;
  min-height: 20px;
  padding-left: 2.3em;
  text-indent: -2.3em;
  position: relative;
`;

const AttentionCheckWrapper = styled.span`
  position: relative;
  top: 2px;
  margin-right: 12px;
`;
