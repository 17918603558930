import React, { FC, Fragment } from "react";

type Props = {
  type: "business_lp";
};

const HubspotEmbedCode: FC<Props> = ({ type }) => {
  if (process.env.NODE_ENV !== "production") return null;

  if (type === "business_lp")
    return (
      <Fragment>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/22607634.js"
        ></script>
      </Fragment>
    );

  return null;
};

export default HubspotEmbedCode;
