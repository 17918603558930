import * as React from "react";
import COLORS from "@utils/COLORS";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

const BlueBelt: FC<PropsWithChildren> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default BlueBelt;

const Wrapper = styled.div`
  display: inline;
  background-color: ${COLORS.deepblue};
  border-radius: 4px;
  padding: 8px 10px;
`;
