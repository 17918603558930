import * as React from "react";
import styled from "styled-components";

type Props = {
  text: string;
  shouldAlignCenter?: boolean; // PC表示の場合のみ適応されます。
};

const TitleWithRainbowLineBorder = ({
  text,
  shouldAlignCenter = false,
}: Props) => {
  return (
    <Wrapper shouldAlignCenter={shouldAlignCenter}>
      <h2 className="lp-rainbow-title">{text}</h2>
    </Wrapper>
  );
};

export default TitleWithRainbowLineBorder;

const Wrapper = styled.div<{ shouldAlignCenter: boolean }>`
  text-align: ${(p) => (p.shouldAlignCenter ? "center" : "left")};
`;
