import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "@components/SectionContainer";
import { H4 } from "@components/Typo";

const DeliverDocument = () => {
  return (
    <SectionContainer size="large" color="white">
      <Wrapper>
        <TitleContainer>
          <h2 className="lp-title">今すぐ無料でお申し込み！</h2>
        </TitleContainer>
        <TextContainer>
          <H4>さぁ、一緒にはじめていきましょう！</H4>
        </TextContainer>
        <ImageWrapper>
          <StaticImage
            src="../../images/play_with_dog.png"
            alt="今すぐ無料でお申し込み！さぁ、一緒にはじめていきましょう！"
          />
        </ImageWrapper>
      </Wrapper>
    </SectionContainer>
  );
};

export default DeliverDocument;

const Wrapper = styled.div``;

const TitleContainer = styled.div`
  margin-bottom: 60px;
  text-align: center;
  @media ${DEVICE.MOBILE} {
    text-align: center;
    margin: 0 auto;
  }
`;

const TextContainer = styled.div`
  text-align: center;
  @media ${DEVICE.MOBILE} {
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 0 120px;
  margin-top: 40px;
  @media ${DEVICE.MOBILE} {
    padding: 0 50px;
  }
`;
