import { getWindow } from "@utils/window";
import React, { FC, useEffect, useMemo } from "react";

type Props = {
  region: string;
  portalId: string;
  formId: string;
};

let _uniqIndex = 1;

/**
 * hubspot の community からコピーしてきました。
 * See: https://community.hubspot.com/t5/APIs-Integrations/HELP-Embed-a-form-in-a-React-component/m-p/230042
 */
const HubspotForm: FC<Props> = ({ region, portalId, formId }) => {
  const domId = useMemo(() => `hubspot-form-${_uniqIndex++}`, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const hbspt = getWindow((w) => (w as any).hbspt);
      // @TS-ignore
      if (hbspt) {
        // @TS-ignore
        hbspt.forms.create({
          region,
          portalId,
          formId,
          target: `#${domId}`,
        });
      }
    });
  }, []);

  return (
    <div>
      <div id={domId}></div>
    </div>
  );
};

export default HubspotForm;
