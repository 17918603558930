import * as React from "react";
import RainbowLine from "./RainbowLine";
import styled from "styled-components";

type Props = React.PropsWithChildren & {
  noPadding?: boolean;
};

const RainbowPanel = ({ children, noPadding = false }: Props) => {
  return (
    <>
      <RainbowLine />
      <ContentWrapper noPadding={noPadding}>{children}</ContentWrapper>
      <RainbowLine />
    </>
  );
};

export default RainbowPanel;

const ContentWrapper = styled.div<{ noPadding: boolean }>`
  margin: ${(p) => (p.noPadding ? "0" : "6px")} 0;
`;
