import { H2 } from "@components/Typo";
import COLORS from "@utils/COLORS";
import DEVICE from "@utils/DEVICE";
import React, { PropsWithChildren } from "react";
import { FC } from "react";
import styled from "styled-components";

type Props = PropsWithChildren & {
  arrow?: boolean;
};

const ActionButton: FC<Props> = ({ children, arrow = false }) => (
  <View>
    <Typo>
      <H2>{children}</H2>
    </Typo>
    {arrow && <Arrow>↗</Arrow>}
  </View>
);

export default ActionButton;

const View = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  background: ${COLORS.buttonGreen};
  box-shadow: 0 4px 0 ${COLORS.buttonGreenShadow};
  border-radius: 8px;
  color: #ffffff;
  padding: 14px;

  min-width: 334px;
  @media ${DEVICE.MOBILE} {
    min-width: 240px;
  }
`;

const Typo = styled.div`
  &,
  * {
    display: inline;
  }
`;

const Arrow = styled.span`
  position: absolute;
  right: 36px;
  font-weight: normal;
  font-size: 20px;
  @media ${DEVICE.MOBILE} {
    font-size: 16px;
    right: 16px;
  }
`;
