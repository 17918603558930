import * as React from "react";
import COLORS from "@utils/COLORS";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

const UnderMarker: FC<PropsWithChildren> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default UnderMarker;

const Wrapper = styled.div`
  display: inline;
  background: linear-gradient(transparent 74%, ${COLORS.yellowMarker} 0%);
`;
