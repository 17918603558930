import * as React from "react";
import styled from "styled-components";
import { DEVICE_WIDTH } from "@utils/DEVICE";

type Props = {
  pc: JSX.Element | null;
  sp: JSX.Element | null;
  threshold?: number; // threshold width
};

const ToggleByWidth = ({ pc, sp, threshold = DEVICE_WIDTH.MOBILE }: Props) => (
  <Wrapper threshold={threshold}>
    {pc && <div className="toggle-pc">{pc}</div>}
    {sp && <div className="toggle-sp">{sp}</div>}
  </Wrapper>
);

export default ToggleByWidth;

const Wrapper = styled.div<{ threshold: number }>`
  .toggle-pc {
    display: block;
  }
  .toggle-sp {
    display: none;
  }
  // SPサイズのスタイル
  @media screen and (max-width: ${(p) => p.threshold}px) {
    /* threshold px以下に適用されるCSS */
    .toggle-pc {
      display: none;
    }
    .toggle-sp {
      display: block;
    }
  }
`;

type OnlyProps = {
  children: JSX.Element;
  threshold?: number;
};

export const OnlySp = ({ children, threshold }: OnlyProps) => (
  <ToggleByWidth threshold={threshold} sp={children} pc={null} />
);

export const OnlyPc = ({ children, threshold }: OnlyProps) => (
  <ToggleByWidth threshold={threshold} pc={children} sp={null} />
);
