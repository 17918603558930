import HubspotForm from "@components/shared/HubspotForm";
import COLORS from "@utils/COLORS";
import * as React from "react";
import styled from "styled-components";

const OPTIONS = {
  region: "na1",
  portalId: "22607634",
  formId: "dcd9ebca-fe7e-43ac-824e-42ed95ea63e0",
};

const RegisterForm = () => (
  <Wrapper>
    <Title>
      <h2 className="lp-title">お申し込みフォーム</h2>
    </Title>
    <HubspotForm {...OPTIONS} />
  </Wrapper>
);

export default RegisterForm;

const Wrapper = styled.div`
  max-width: 560px;
  margin: 0 auto;
  background-color: ${COLORS.paleOrange};
  padding: 24px 24px 4px 24px;
  border-radius: 12px;
`;

const Title = styled.div`
  margin-bottom: 16px;
`;

/*
以下のHTMLに相当します。
<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
<script>
  hbspt.forms.create({
    region: "na1",
    portalId: "22607634",
    formId: "19625799-d094-4d2e-a964-ac5eaeef1533"
  });
</script>
*/
