import TwoTilesHero from "@components/TwoTilesHero";
import * as React from "react";
import styled from "styled-components";
import COLORS from "@utils/COLORS";
import DEVICE from "@utils/DEVICE";
import SectionContainer from "@components/SectionContainer";
import { StaticImage } from "gatsby-plugin-image";

const IntroduceDetails = () => {
  return (
    <SectionContainer color="white" size="large">
      <Wrapper>
        <TwoTilesHero
          title={`ペットオーナー集客の要点である\n「ペット同伴可施設の紹介」と「施設訪問時の手続き簡略化」を Wan!Pass（ワンパス）が実現します。`}
          details={[
            {
              text: "Wan!Pass（ワンパス）でペット同伴可施設をご紹介、ペットオーナーはアプリ上で簡単にお出かけ先を検索できます。",
              image: (
                <StaticImage
                  src="../../images/introduce_details/wanpass_util_image.png"
                  alt="ペットオーナーはワンパス上で簡単にお出かけ先を検索できます。"
                />
              ),
            },
            {
              text: "「狂犬病予防注射済み証」「ワクチン予防接種証明書」をアプリに登録できるので、ペッオーナーも御社施設もアプリの内容を確認するだけ！面倒な書類管理は一切不要です。",
              image: (
                <StaticImage
                  src="../../images/introduce_details/wanpass_certificate_image.png"
                  alt="ワンパスは「狂犬病予防注射済み証」「ワクチン予防接種証明書」をアプリに登録できます。"
                />
              ),
            },
          ]}
        />

        <TwoTilesHero
          title={`さらにWan!Pass（ワンパス）は、\nペットトラブルも未然に防止することができます。`}
          details={[
            {
              text: "ペットオーナーがアプリに登録する「狂犬病・ワクチン証明書の有無」「わんちゃんの大きさ」情報をもとに、御社施設の入店可否を判別、施設訪問前にペットオーナーに告知します。",
              image: (
                <StaticImage
                  src="../../images/introduce_details/wanpass_screen_image.png"
                  alt="ワンパスは御社施設の入店可否を判別、施設訪問前にペットオーナーに告知します。"
                />
              ),
            },
            {
              text: (
                <div>
                  施設を利用する際の細かい注意点をアプリに記載、トラブルを未然に防止します。
                </div>
              ),
              image: (
                <StaticImage
                  src="../../images/introduce_details/wanpass_screen_check.png"
                  alt="ワンパスは施設を利用する際の細かい注意点をアプリに記載、トラブルを未然に防止します。"
                />
              ),
            },
          ]}
        />

        <TwoTilesHero
          title={`端末などの事前準備は一切不要。\nご利用料金もずっと0円でカンタンにご利用いただけます。`}
          details={[
            {
              text: "QRコードが印字されたカードを設置するだけでご利用いただけます。",
              image: (
                <StaticImage
                  src="../../images/introduce_details/wanpass_qr.png"
                  alt="ワンパスはQRコードが印字されたカードを設置するだけでご利用いただけます。"
                />
              ),
            },
            {
              text: "「アプリ内マップに御社の店舗を掲載」「狂犬病・ワクチン証明書の電子管理」「施設利用同意書の電子管理」機能が無料でご利用いただけます。",
              image: (
                <StaticImage
                  src="../../images/introduce_details/free_happy_guy.png"
                  alt="ワンパスはご利用料金もずっと0円でカンタンにご利用いただけます。"
                />
              ),
            },
          ]}
        />
      </Wrapper>
    </SectionContainer>
  );
};

export default IntroduceDetails;

const Wrapper = styled.div`
  background-color: ${COLORS.white};
  @media ${DEVICE.MOBILE} {
    display: block;
  }
`;
