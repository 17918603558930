import * as React from "react";
import styled from "styled-components";
import DEVICE from "@utils/DEVICE";
import ResponsiveColumns from "@components/ResponsiveColumns";
import COLORS from "@utils/COLORS";

type Props = {
  image: JSX.Element;
  text: JSX.Element;
  marginBottom?: number;
  mobileWidth?: number;
};

const TextAndImageCard = ({
  image,
  text,
  marginBottom = 0,
  mobileWidth = undefined,
}: Props) => {
  return (
    <ResponsiveColumnsContainer marginBottom={marginBottom}>
      <ResponsiveColumns mobileWidth={mobileWidth}>
        {[
          <ImageWrapper key="left">{image}</ImageWrapper>,
          <RightContent key="right">{text}</RightContent>,
        ]}
      </ResponsiveColumns>
    </ResponsiveColumnsContainer>
  );
};

export default TextAndImageCard;

const RightContent = styled.div`
  background-color: #ffffff;
  padding: 25px 20px;
  @media ${DEVICE.MOBILE} {
    width: 100%;
    margin: 15px auto;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  @media ${DEVICE.MOBILE} {
    max-width: 375px;
  }
`;

const ResponsiveColumnsContainer = styled.div<{
  marginBottom: number;
}>`
  background-color: ${COLORS.white};
  margin-bottom: ${(p) => p.marginBottom}px;
`;
