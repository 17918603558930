import { StaticImage } from "gatsby-plugin-image";
import React, { FC } from "react";
import IconWrapper, { IconWrapperSizeProps } from "./IconWrapper";

type Props = IconWrapperSizeProps;

export const CheckIcon: FC<Props> = ({ ...props }) => (
  <IconWrapper {...props}>
    <StaticImage src="../../images/shared/check_icon.png" alt="ポイント" />
  </IconWrapper>
);

export const TelIcon: FC<Props> = ({ ...props }) => (
  <IconWrapper {...props}>
    <StaticImage src="../../images/shared/tel_icon.png" alt="ポイント" />
  </IconWrapper>
);
